import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/end-user-data-form/:id?',
        beforeEnter: (to, from, next) => {
            if (to.params.id && to.params.id.length > 23) {
                next();
            }
            else {
                next('/404-page-not-found');
            }
        },
        component: () => import('@/views/EndUserDataView.vue'),
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
